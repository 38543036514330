<template>
  <v-container>
    <v-row>
      <v-col>
        {{ $trans("api_description") }}
      </v-col>
    </v-row>
    <v-row v-if="apiKey">
      <v-col>
        <v-text-field
          :value="tenantName"
          :label="$trans('tenant_name')"
          color="primary"
          outlined
          readonly
          append-outer-icon="$copy"
          @click:append-outer="$helpers.copyAndNotify(tenantName)"
        />
        <v-text-field
          :value="apiKey"
          :append-icon="showApiKey ? '$eye-on' : '$eye-off'"
          :label="$trans('api_key')"
          :type="showApiKey ? 'text' : 'password'"
          color="primary"
          outlined
          readonly
          append-outer-icon="$copy"
          @click:append="showApiKeyHandler"
          @click:append-outer="$helpers.copyAndNotify(apiKey)"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="d-flex align-center justify-center">
        <v-btn color="blue" outlined :loading="isLoading" @click="createHandle">
          <span v-text="$trans('generate_api_key_button')" />
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-center justify-center">
        <v-btn color="blue" outlined @click="openApiDocumentation">
          <span v-text="$trans('open_api_documentation')" />
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-center justify-center">
        <v-btn
          v-if="apiKey"
          color="error"
          outlined
          :loading="isLoading"
          @click="deleteHandle"
        >
          <span v-text="$trans('delete_api_key')" />
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "ApiIntegration",
  mixins: [sharedActions],
  data() {
    return {
      isLoading: false,
      showApiKey: false,
    };
  },
  computed: {
    ...mapGetters({
      tenantName: "setup/getTenant",
      storedApiKey: "integrations/getApiKey",
      apiKey: "integrations/getApiKey",
    }),
  },
  methods: {
    ...mapActions({
      deleteApiKey: "integrations/deleteApiKey",
      createApiKey: "integrations/createApiKey",
    }),
    showApiKeyHandler() {
      this.showApiKey = !this.showApiKey;
    },
    createHandle() {
      this.isLoading = true;
      this.createApiKey()
        .then()
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteHandle() {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmationText: this.$trans("delete"),
        confirmationButtonColor: "error",
        hideImportantInformation: true,
        confirmAction: () => {
          this.setCommonDialogLoader(true);
          this.deleteApiKey()
            .then()
            .catch((error) => {
              errorNotification(null, error);
            })
            .finally(() => {
              this.setCommonDialogLoader(false);
              this.closeConfirmDialog();
            });
        },
      });
    },
    openApiDocumentation() {
      window.open("https://api-doc.calendesk.com/", "_blank");
    },
  },
};
</script>
